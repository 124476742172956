<template>
  <div visual2 :class="{ready}" :style="{height: this.mainKvH}">
    <div class="kv" :class="bg"></div>
    <div class="kv-inner">
      <h2>{{ tit }}</h2>
      <p class="txt" v-html="txt"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Visual2",
  props:{
    tit: { type: String, default: null },
    txt: { type: String, default: null },
    bg: { type: String, default: null }
  },
  data() {
    return {
      ready: true,
      scrollPosition: null,
      dir: null,
      mainKvH: '100vh',
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    setTimeout(() =>{ this.ready = false },500);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.scrollPosition = document.documentElement.scrollTop || 0;
    this.mainKvHeight();
  },
  watch: {
    $route(to, from) {
      if(to.path !== from.path) {
        this.$router.go(this.$router.currentRoute);
      }
    }
  },
  methods: {
    handleScroll() {
      let docY = document.documentElement.scrollTop;
      this.dir = docY - this.scrollPosition >= 0 ? 1 : -1;
      this.scrollPosition = docY
      this.kvToggle();
    },
    kvToggle() {
      if(this.dir === 1) { this.ready = true; }
      if(this.scrollPosition < 10) { this.ready = false; }
    },
    mainKvHeight() {
      if(window.innerWidth < 768) {
        this.mainKvH = window.innerHeight + 'px';
      } else if(window.innerWidth > 1024) {
        this.mainKvH = (window.innerHeight + 37)+ 'px';
      } else if(window.innerWidth > 1400) {
        this.mainKvH = (window.innerHeight + 22)+ 'px';
      }
    },
    handleResize() {
      this.mainKvHeight();
    },
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[visual2] { .h(100vh); .rel;
  .kv { .wh(100%,100vh); .fix; .lt; .cover; transition: transform 1s; transform: scale(1); .ease-out; .mt(120);
    &.brand-kv { background: url("/images/mo/brand-kv.png")no-repeat center center; }
    &.product-kv { background: url("/images/mo/product-kv.png")no-repeat center center; }
  }
  .kv-inner { .abs; .lt(50%,50%); .wf; transform: translate(-50%,-50%); .mt(100); .tc; .c(#fff); overflow: hidden; transition: opacity 0.5s;
    h2 { .fs(100); .lh(130); .roboc; .bold; }
    p { .fs(48); .lh(72); .regular; .mt(65); .ggo; transition-delay: 0.3s;
      b { .bold; }
      .kv-logo { .wh(250,48); .contain('/images/mo/logo-w2.png'); .ib; .vam; }
    }
  }
  h2, p, .sd { transform: translateY(0); transition: transform 1s, opacity 0.5s; .ease-out; }
  &.ready {
    .kv { transform: scale(1.2); }
    h2, p, .sd { transform: translateY(100px); opacity: 0; }
  }
}

@media screen and(min-width:1024px) {
  [visual2] { .rel;
    .kv { .mt(0);
      &.brand-kv { background: url("/images/pc/brand-kv.png")no-repeat center center; .cover; }
      &.product-kv { background: url("/images/pc/product-kv.png")no-repeat center center; .cover; }
    }
    .product-kv + .kv-inner .txt .kv-logo { .wh(316,75); .mt(0); }
    .kv-inner { .max-w(1400);
      p { .fs(60); .lh(72); .mt(80); .tc; width:auto;
        .kv-logo { .wh(297,58); .contain('/images/pc/logo-w2.png'); .ib; .vam; .mt(10); }
      }
    }
    &.ready {
      .kv { transform: scale(1.1); }
    }
  }
}

</style>