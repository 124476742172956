<template>
  <Modal contact-modal>
    <div class="contact-form">
      <h3>H CORE <i>|</i> 문의하기</h3>
      <p class="sub">문의를 남겨주시면 담당자가 확인 후 메일을 보내드립니다.</p>
      <div class="form-list">
        <div class="list">
          <p>구분</p>
          <div class="select" :class="{on:isOn}">
            <a class="selected" @click="selectOpen">{{ param.category }}</a>
            <ul>
              <li @click="changeCategory('형강')">형강</li>
              <li @click="changeCategory('철근')">철근</li>
              <li @click="changeCategory('후판')">후판</li>
              <li @click="changeCategory('강관')">강관</li>
              <li @click="changeCategory('열연')">열연/냉연</li>
              <li @click="changeCategory('기타')">기타</li>
            </ul>
          </div>
        </div>
        <div class="list">
          <p>업체(상호)명</p>
          <input type="text" v-model="param.company">
        </div>
        <div class="list">
          <p> 담당자 <i>*</i></p>
          <input type="text" v-model="param.name">
        </div>
        <div class="list">
          <p>적용현장</p>
          <input type="text" v-model="param.site">
        </div>
        <div class="list">
          <p>이메일 <i>*</i></p>
          <input type="text" v-model="param.email">
          <span>*작성하신 이메일로 답변이 발송됩니다.</span>
        </div>
        <div class="list">
          <p>전화번호 <i>*</i></p>
          <input type="text" v-model="param.phone">
        </div>
        <div class="list">
          <p>문의내용 <i>*</i></p>
          <textarea v-model="param.cont"></textarea>
          <span>* 필수항목을 정확하게 입력해주세요.</span>
        </div>
        <div class="list agree">
          <p class="">
            개인정보수집 및 처리 위탁에 대한 안내<br>
            <br>
            [개인정보 수집 및 이용 동의]<br>
            '현대제철'은 고객님의 문의요청에 정확하고 성실한 답변을 드리기 위해 필요한 최소한의 개인정보를 수집하고 있습니다.<br>
            수집 및 이용목적 : 1:1문의에 대한 답변<br>
            수집항목 : 이름, 휴대폰번호, 이메일주소<br>
            보유기간 : 1년<br>

            [수집한 개인정보 위탁 동의]<br>
            현대제철은 다음과 같이 개인정보 열람 및 처리업무를 위탁하고 있습니다.<br>

            - 개인정보 위탁 업체 : (주)디퍼플<br>
            - 개인정보 위탁목적 : 홈페이지관리, 1:1문의에 대한 답변<br>
            - 제공받는 개인정보 항목 : 이름, 휴대폰번호, 이메일주소<br>
            - 제공받는 자의 보유 및 이용기간 : 1년
          </p>
        </div>
      </div>
      <a class="submit" @click="submit">확인</a>
    </div>
    <a class="close" @click="$emit('resolve')">닫기</a>
  </Modal>
</template>

<script>
import Modal from "@/views/components/modal/Modal";
import axios from 'axios'
export default {
  name: "ContactModal",
  components: {Modal},
  props: ['options'],
  data() {
    return {
      isOn: false,
      param: {
        category: '',
        company: '',
        name: '',
        site: '',
        email: '',
        phone: '',
        cont: '',
      }
    }
  },
  methods: {
    selectOpen() {
      this.isOn =! this.isOn;
    },
    changeCategory(v) {
      this.param.category = v;
      this.isOn = false;
    },
    async submit() {
      if (!this.param.name) {
        alert('담당자를 입력해주세요');
        return;
      }

      if (!this.param.email) {
        alert('이메일을 입력해주세요');
        return;
      }

      if (!this.param.phone) {
        alert('전화번호를 입력해주세요');
        return;
      }

      if (!this.param.cont) {
        alert('문의내용을 입력해주세요');
        return;
      }

      await axios.post('/api/qna', this.param);
      this.$emit('resolve');
      alert('문의가 접수되었습니다.');
    }
  },
  mounted() {
    this.param.category = this.options.category;
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[contact-modal] {
  .contact-form { .w(93%); .p(60,80,55); .-box; .bgc(#fff); .-a(#ababab); .tc; .mh-c;
    h3 { .fs(36); .lh(40); .ls(-0.025em); .bold;
      i { .thin; .ib; .m(0,15); }
    }
    .sub { .fs(24); .lh(36); .ls(-0.025em); .mt(20); color:#999; }
    .form-list { .mt(36);
      .list { .tl; .mb(15);
        &:nth-child(1) p { .lh(50); }
        p { .fs(20); .lh(60); .w(20%); .ib; .vam;
          i { color:#ca5c5c; }
        }
        input, .select { .-a(@c-black); .h(60); .lh(60); .ib; .vam; .pl(20); .-box; }
        input { .fs(20); .w(80%); .spoqa; }
        .select { .wh(200,48); .lh(48); .rel; .pl(0);
          &:after { .cnt; .wh(26,13); .contain('/images/mo/ico-select-arrow.png'); .abs; .rt(16,18); pointer-events: none; }
          .selected { .fs(24); .pointer; z-index: 1; .ib; .f; .pl(20); .-box; }
          ul { .wh(200,0); .abs; .lt(-2,46); .-box; .-t; .bgc(#fff); .crop; }
          &.on {
            &:after { transform: rotate(180deg); }
            ul { height:auto; .-a(@c-black);
              li { .fs(24); .-a; .f; .lh(58); .vat; background: transparent; .pl(20); .-box;
              }
            }
          }
        }
        span { .fs(20); .pl(20%); .-box; .mt(5); .ib; color:#0079ae; }
        textarea { .fs(20); .wh(100%,120); .-a(@c-black); .-box; .p(20); .spoqa; }
        textarea + span { .pl(0); color:#ca5c5c; }
        &.agree { .p(15); .-box; .-a(@c-black); .h(80); .scroll;
          p { .wf; .lh(32); }
        }
      }
    }
    .submit { .fs(24); .tc; .wh(250,60); .lh(60); .bgc(#0d1112); .ib; color:#fff; .mt(10); }
  }
}

@media screen and(min-width:1024px) {
  [contact-modal] {
    .contact-form { .w(550); .p(50,60,45); .-box; .wf;
      h3 { .fs(30); .lh(36); }
      .sub { .fs(18); .lh(36); .mt(12); }
      .form-list { .mt(45);
        .list {
          p { .fs(18); .lh(40); .w(125); }
          input, .select { .h(40); .lh(40); }
          input { .fs(18); .w(300); }
          .select { .wh(200,50); .lh(50);
            .selected { .fs(18); }
            &.on {
              ul { .lt(-1,48);
                li { .fs(18); .lh(50); .pointer;
                  &:hover { .bgc(#efefef); }
                }
              }
            }
          }
          span { .fs(16); .pl(125); }
          textarea { .fs(18); .h(100); }
          &.agree { .h(60);
            p { .fs(14); .lh(20); }
          }
        }
      }
      .submit { .fs(16); .wh(250,40); .lh(40); }
    }
  }
}
</style>